import jQuery from "jquery";
const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)


console.log('Advanced Custom Options');


document.addEventListener('DOMContentLoaded', function() {
    // PHPから渡されたデータを使用
    // console.log(acoData);


    // Googleアナリティクスを初期化する関数
    function initializeGA4(gtagID, ad_storage, analytics_storage) {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagID}`;
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
                'ad_storage': ad_storage, // 広告ストレージ
                'analytics_storage': analytics_storage // 分析ストレージ
            });
            gtag('js', new Date());
            gtag('config', gtagID);
        };
        // alert('initializeGA4('+ad_storage+'/'+analytics_storage+')');
    }


    // GDPR
    if(acoData.gdpr) {
      console.log(acoData.gdpr_agreement_text_ja);

      const userLang = navigator.language || navigator.userLanguage;
      let gdpr_agreement_text, gdpr_agreement_link, gdpr_agreement_reject, gdpr_agreement_accept, privacypolicy_url;
      if (userLang.startsWith('ja')) {
        gdpr_agreement_text = acoData.gdpr_agreement_text_ja;
        gdpr_agreement_link = '詳細';
        gdpr_agreement_reject = '拒否';
        gdpr_agreement_accept = '同意';
        privacypolicy_url = acoData.privacypolicy_url_ja;
      } else {
        gdpr_agreement_text = acoData.gdpr_agreement_text_en;
        gdpr_agreement_link = 'Detail';
        gdpr_agreement_reject = 'Reject';
        gdpr_agreement_accept = 'Accept';
        if(acoData.privacypolicy_url_en){
          privacypolicy_url = acoData.privacypolicy_url_en;
        } else {
          privacypolicy_url = acoData.privacypolicy_url_ja;
        }
      }


      /*
      Cookie同意
      */
      // Cookieを設定する関数
      function setCookie(name, value, days) {
          const expires = new Date(Date.now() + days * 864e5).toUTCString();
          document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=/';
      }

      // Cookieの値を取得する関数
      function getCookie(name) {
          return document.cookie.split('; ').reduce((r, v) => {
              const parts = v.split('=');
              return parts[0] === name ? decodeURIComponent(parts[1]) : r;
          }, '');
      }

      // Cookieを削除する関数
      function deleteCookie(name) {
          setCookie(name, '', -1);
      }

      // 同意がまだ得られていない場合、Cookie同意バナーを表示する
      function displayCookieConsentBanner() {
          const consent = getCookie('cookieConsent');
          if (consent === '') {
              const consentBanner = document.createElement('div');
              consentBanner.id = 'cookie-consent-banner';
              consentBanner.innerHTML = `
                  <div>
                    <p class="agreement">${gdpr_agreement_text} <a href="${privacypolicy_url}">${gdpr_agreement_link}</a></p>
                    <div class="button">
                      <button id="reject-cookies">${gdpr_agreement_reject}</button>
                      <button id="accept-cookies">${gdpr_agreement_accept}</button>
                    </div>
                  </div>
              `;
              document.body.appendChild(consentBanner);

              document.getElementById('accept-cookies').addEventListener('click', () => {
                  setCookie('cookieConsent', 'true', 365);
                  initializeGA4(acoData.gtag, 'granted', 'granted'); // ここにあなたのGA4トラッキングIDを入力します
                  consentBanner.style.display = 'none';
              });

              document.getElementById('reject-cookies').addEventListener('click', () => {
                  setCookie('cookieConsent', 'false', 365);
                  initializeGA4(acoData.gtag, 'denied', 'denied'); // ここにあなたのGA4トラッキングIDを入力します
                  consentBanner.style.display = 'none';
              });

              // 同意が未取得の場合、'denied' として初期化
              initializeGA4(acoData.gtag, 'denied', 'denied'); // ここにあなたのGA4トラッキングIDを入力します
          } else if (consent === 'true') {
              initializeGA4(acoData.gtag, 'granted', 'granted'); // ここにあなたのGA4トラッキングIDを入力します
          } else if (consent === 'false') {
              initializeGA4(acoData.gtag, 'denied', 'denied'); // ここにあなたのGA4トラッキングIDを入力します
          }

      }

      // 同意の撤回を処理する関数
      function handleConsentWithdrawal() {
          deleteCookie('cookieConsent');
          deleteCookie('_ga'); // 例：GA4によって設定された実際のクッキー名に置き換えてください
          deleteCookie('_gid'); // 例：GA4によって設定された実際のクッキー名に置き換えてください
          alert('Cookieの同意が撤回されました。ページをリロードしてください。');
      }

      // ページ読み込み時に実行
      window.addEventListener('load', displayCookieConsentBanner);

    } else {
      initializeGA4(acoData.gtag, 'granted', 'granted'); // ここにあなたのGA4トラッキングIDを入力します

    }
});


// import文を使ってSassファイルを読み込む
import "../scss/aco-frontend.scss";
